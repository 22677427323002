import React from "react"

const BackArrow = ({
  width = 16,
  height = 16,
  color = "#9E9FA2",
  viewBox = "0 0 16 16",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 2L5 8L11 14" stroke={color} strokeWidth="1.3" />
    </svg>
  )
}

export default BackArrow
