import React from "react"

const FaceBookBgWhite = ({
  width = 44,
  height = 44,
  color = "#336BFF",
  viewBox = "0 0 44 44",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx={(height - 1) / 2}
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx={(height - 1) / 2}
        stroke="#E6E6E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1083 18.4584H23.5583V16.8667C23.5346 16.6316 23.6119 16.3976 23.7708 16.2228C23.9297 16.048 24.1554 15.9488 24.3916 15.95H26.8916V12.6167H23.2833C20.0083 12.6167 19.3916 15.1167 19.3916 16.6667V18.4584H16.8916V21.7917H19.3916V31.375H23.5583V21.7917H26.7666L27.1083 18.4584Z"
        fill={color}
      />
    </svg>
  )
}

export default FaceBookBgWhite
