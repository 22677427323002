import { Layout, SEO } from "components"
import { graphql, navigate } from "gatsby"
import * as React from "react"
import "./details.scss"
import BackArrow from "components/ReactSvgIcons/BackArrow"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { formatDate } from "utils/dateFunctions"
import TwitterBgWhite from "components/ReactSvgIcons/twitterBgWhite"
import LinkedinBgWhite from "components/ReactSvgIcons/LinkedinBgWhite"
import FaceBookBgWhite from "components/ReactSvgIcons/faceBookBgWhite"
import SocialLinkBgWhite from "components/ReactSvgIcons/SocialLinkBgwhite"
import SmartLink from "components/SmartLink/SmartLink"
import CtaButtonList from "components/CtaButtonList/CtaButtonList"

interface IBlogPageTemplateProps {
  data: any
}

const CaseStudyDetailsPage: React.FunctionComponent<IBlogPageTemplateProps> = ({
  data,
}) => {
  const val = data?.prismicCaseStudies?.data
  const handleClick = (e: any) => {
    e.preventDefault()
    if (typeof window !== "undefined") {
      if (window.history.length > 1) {
        navigate(-1)
      } else {
        navigate("/case-studies")
      }
    }
  }

  if (!data) {
    return null
  }
  const doc = val?.body?.[0]?.primary

  function copyCurrentUrl() {
    navigator.clipboard.writeText(window.location.href).catch(err => {
      console.error("Failed to copy URL: ", err)
    })
  }

  return (
    <Layout>
      <SEO title={doc?.title || ""} description={doc?.description || ""} />
      <div className="caseStudyDetails">
        <div className="header">
          <div role="button" onClick={handleClick} className="backArrow">
            <BackArrow />
            <div className="text">Back to all posts</div>
          </div>

          <div className="title">
            {val?.body1[0]?.primary?.case_study_title?.text}
          </div>
        </div>
        <GatsbyImage
          objectFit="cover"
          className="feature-image"
          alt={val?.body1[0]?.primary?.feature_image?.alt}
          image={val?.body1[0]?.primary?.feature_image?.gatsbyImageData}
        />
        <div className="content-section">
          <div className="sociallinks">
            <div className="date">{formatDate(val?.published)}</div>
            <div className="links">
              <SmartLink url="https://x.com/TeambleTeam">
                <TwitterBgWhite width={44} height={44} />
              </SmartLink>
              <SmartLink url="https://www.linkedin.com/company/teamble/">
                <LinkedinBgWhite width={44} height={44} />
              </SmartLink>
              <SmartLink url="https://www.facebook.com/TeambleTeam#">
                <FaceBookBgWhite width={44} height={44} />{" "}
              </SmartLink>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={copyCurrentUrl}
              >
                <SocialLinkBgWhite width={44} height={44} />
              </div>
            </div>
          </div>
          <div className="content">
            <PrismicRichText
              field={val?.body1[0]?.primary?.content?.richText}
              components={{
                strong: (props: any) => (
                  <strong className="strong">{props.children}</strong>
                ),
                heading1: (props: any) => (
                  <h1 className="header-1">{props.children}</h1>
                ),
                heading2: (props: any) => (
                  <h2 className="header-2">{props.children}</h2>
                ),
                paragraph: (props: any) => (
                  <p className="para">{props?.children}</p>
                ),
                list: (props: any) => (
                  <ul className="list">{props.children}</ul>
                ),
                listItem: (props: any) => (
                  <li className="list-items">{props.children}</li>
                ),
                image: ({ node }: any) => (
                  <img
                    src={node.url}
                    alt={node.alt || ""}
                    className="image-card"
                  />
                ),
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CtaButtonList />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($uid: String) {
    prismicCaseStudies(uid: { eq: $uid }) {
      data {
        published
        body1 {
          ... on PrismicCaseStudiesDataBody1CaseStudy {
            id
            slice_type
            primary {
              author_description
              author_name
              case_studies_category
              case_study_title {
                text
                richText
                raw
                html
              }
              content {
                text
                richText
                raw
                html
              }
              feature_image {
                alt
                gatsbyImageData
                url
              }
            }
          }
        }
        body {
          ... on PrismicCaseStudiesDataBodyGeneralCard {
            id
            slice_type
            slice_label
            primary {
              description
              title
            }
          }
        }
      }
    }
  }
`

export default CaseStudyDetailsPage
