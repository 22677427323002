import React from "react"

const TwitterBgWhite = ({
  width = 44,
  height = 44,
  color = "#336BFF",
  viewBox = "0 0 44 44",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx={(height - 1) / 2}
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx={(height - 1) / 2}
        stroke="#E6E6E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.3169 17.5917L29.5669 16.8417L30.4836 14.8417L28.3502 15.55C27.6707 14.9167 26.7791 14.56 25.8502 14.55C23.7811 14.5546 22.1048 16.2309 22.1002 18.3V19.1334C19.1502 19.7417 16.5752 18.1334 14.1836 15.3834C13.7669 17.6056 14.1836 19.2723 15.4336 20.3834L12.6919 19.9667C12.9105 21.7884 14.402 23.1921 16.2336 23.3L13.9419 24.1334C14.7752 25.8 16.2919 26.0584 18.3169 26.2167C16.6617 27.3456 14.6949 27.9284 12.6919 27.8834C23.3252 32.6084 29.5669 25.6667 29.5669 19.55V18.8584L31.3169 17.5917Z"
        fill={color}
      />
    </svg>
  )
}

export default TwitterBgWhite
