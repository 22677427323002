import SmartLink from "components/SmartLink/SmartLink"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "./CtaButtonList.scss"
import useGetIntegrationItemList from "hooks/useGetIntegrationItemList"

const CtaButtonList = () => {
  const { integrationitems } = useGetIntegrationItemList()

  return (
    <div className="integrationItems">
      {integrationitems?.map((item: any, index: number) => (
        <SmartLink key={index} url={item?.url?.url}>
          <div className="integrationInfo">
            {item?.integration_images?.gatsbyImageData && (
              <GatsbyImage
                image={item?.integration_images?.gatsbyImageData}
                alt={item?.integration_images.alt ?? "Review item icons"}
                objectFit="contain"
              />
            )}
            <div className="name">
              <div className="mobile-text">{item?.mobilename}</div>
              <div className="desktop-text">{item?.name}</div>
            </div>
          </div>
        </SmartLink>
      ))}
    </div>
  )
}

export default CtaButtonList
