import React from "react"

const LinkedinBgWhite = ({
  width = 44,
  height = 44,
  color = "#336BFF",
  viewBox = "0 0 44 44",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx={(height - 1) / 2}
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx={(height - 1) / 2}
        stroke="#E6E6E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.725 28.8584H12.675V17.25H16.725V28.8584ZM25.1333 21.0334C24.1484 21.0334 23.35 21.8318 23.35 22.8167V28.8584H19.0916V17.25H23.35V18.575C24.3481 17.7301 25.609 17.2587 26.9166 17.2417C29.5583 17.2417 31.3916 19.2 31.3916 22.9167V28.8584H26.9166V22.8167C26.9188 22.3423 26.7319 21.8865 26.3973 21.5503C26.0626 21.214 25.6077 21.025 25.1333 21.025V21.0334ZM16.8 13.2334C16.8 14.384 15.8672 15.3167 14.7166 15.3167C13.566 15.3167 12.6333 14.384 12.6333 13.2334C12.6333 12.0828 13.566 11.15 14.7166 11.15C15.8607 11.1592 16.7833 12.0892 16.7833 13.2334H16.8Z"
        fill={color}
      />
    </svg>
  )
}

export default LinkedinBgWhite
